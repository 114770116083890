import { Button } from '@/components/ui/atoms/button'
import { Card, CardHeader } from '@/components/ui/atoms/card'
import { store as cookies_store } from '@/store/cookies'
import { actions as cookies_actions } from '@/store/cookies'
import { useStore } from '@nanostores/react'

export default function CookiesBanner() {
  const $cookies = useStore(cookies_store.cookies)
  const handleClick = () => cookies_actions.consent()

  if ($cookies?.consent) return null

  return (
    <div className="fixed bottom-0 left-0 w-full p-2 md:p-4">
      <Card className="max-w-7xl shadow-lg border mx-auto p-1 md:p-2">
        <CardHeader className="text-xs text-zinc-900 mx-auto py-4 px-4 md:py-4 md:px-8">
          <div className="flex items-center justify-between text-center md:text-left flex-col md:flex-row gap-4 md:gap-6">
            <p>
              Utilizamos cookies para proporcionar aos nossos usuários a melhor
              experiência no nosso site. Saiba mais sobre a utilização de
              cookies em nossa{' '}
              <a
                href="/politica-de-privacidade"
                className="inline-flex underline font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade.
              </a>
            </p>
            <Button
              type="button"
              className="bg-zinc-800 hover:bg-zinc-900 text-zinc-100 rounded-lg"
              size="sm"
              onClick={handleClick}
            >
              Aceitar e sair
            </Button>
          </div>
        </CardHeader>
      </Card>
    </div>
  )
}
